$bestridesharelawyers-slogan-color: #686868;
$bestridesharelawyers-progressBar-color: #b0b0b0;
$bestridesharelawyers-button-color: #a2c23a;
$bestridesharelawyers-little-span-color: #879f33;
$bestridesharelawyers-before-img-color: #f3f3f3;
$bestridesharelawyers-before-uber-accident-color: #22394f;
$bestridesharelawyers-fields-color: #a2c23a;
$bestridesharelawyers-hover-selected-color: #5e7b00;

:export {
    sloganColor: $bestridesharelawyers-slogan-color;
    progressBarColor: $bestridesharelawyers-progressBar-color;
}
