
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.greenSpan {
    background-color: $bestridesharelawyers-little-span-color;
}
.behindImg {
    position: relative;
    margin: 50px auto;
    @include lg {
        margin: 50px auto 50px 35px;
    }
    &::before {
        content: "";

        width: 72px;

        bottom: -20px;
        top: -20px;
        left: -20px;
        height: 330px;
        position: absolute;
        z-index: -1;

        background-color: $bestridesharelawyers-before-img-color;

        @include lg {
            width: 172px;
            height: 469px;
        }
    }
}
.behindImgTop {
    position: relative;
    @include lg {
        margin: 50px 15px 50px auto;
    }
    margin: 50px auto;
    &::before {
        content: "";

        width: 72px;

        bottom: -20px;
        top: -20px;
        right: -20px;
        height: 330px;
        position: absolute;
        z-index: -1;

        background-color: $bestridesharelawyers-before-img-color;
        @include lg {
            width: 172px;
            height: 469px;
        }
    }
}
.mainContent {
    width: fit-content;
    @include lg {
        margin: unset;
    }
    margin: 0 auto;
}
.unorderedList {
    list-style-type: disc;
    // margin-left: 30px;
    margin: 20px 0 20px 30px;
}
